.setting-popup {
 position: absolute;
 top: 0;
 left: 0;
 height: 100%;
 width: 100%;
 background-color: rgba(0, 0, 0, 0.6);
 z-index: 9999;

 .setting-box {
  position: absolute;
  width: 27vw;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;

  .setting-bg {
   width: 100%;
   object-fit: cover;
  }

  .setting-box {
   background-color: #fff;
   width: 80%;
   height: 80%;
   border: 0.5vw solid #7dd4f0;
   border-radius: 3vw;
   padding: 0 1.3vw;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   font-family: "Baloo Tammudu 2", cursive;
   font-weight: 900;

   .fr {
    position: absolute;
    height: 1vw;
    width: 1vw;
    background-color: #fff;
    top: -0.5vw;
    right: 1.7vw;
    transform: rotate(45deg);
   }

   .se-div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
     margin-bottom: 0vw;
     font-size: 1.3vw;
     font-weight: 600;
     margin-top: 0.5vw;
     font-family: "Baloo Tammudu 2", cursive;
     font-weight: 900;
    }

    .switch {
     display: inline-block;
     position: relative;
     width: 3vw;
     height: 1.8vw;
     border-radius: 4vw;
     background: #c2c2c2;
     transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
     vertical-align: middle;
     cursor: pointer;
     margin-bottom: 0;
    }

    .switch::before {
     content: "";
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     left: 0.2vw;
     width: 1.4vw;
     height: 1.4vw;
     background: #fafafa;
     box-shadow: 0.2vw 0.3vw 0.3vw rgba(0, 0, 0, 0.6);
     border-radius: 50%;
     transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
      box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .switch:active::before {
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
    }

    input:checked + .switch {
     background: #00a651;
    }

    input:checked + .switch::before {
     left: 1.4vw;
     background: #fff;
    }

    input:checked + .switch:active::before {
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
    }
   }

   .btn-seting-reset {
    background-color: #ee6d7a;
    border-radius: 1vw;
    display: block;
    font-size: 1.5vw;
    height: 3vw;
    color: #fff;
    line-height: 3vw;
    font-family: "Baloo Tammudu 2", cursive;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
   }

   .btn-seting-reset:hover {
    color: #fff;
   }
  }
 }
}
.setting-popup.active {
 display: none;
}
