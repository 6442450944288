#audio-container {
    display: block;
    background: #fff;
    width: unset;
    height: auto;

    .togglePlay {
        margin: 0;
        width: 2.2vw;
        height: 2.2vw;
        font-size: 1vw;
        background: #00a651;
        border: none;
        border-radius: 50%;
        color: #fff;
        outline: none;
        margin-right: 0.4vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #video {
        margin: 0;
        padding: 0vw;
        border-radius: 1vw;
        width: 35vw;
        height: 29.5vw;
    }

    #controls {
        display: flex;
        margin: 0;
        padding-right: 1vw;
        border-radius: 0.5vw;
        align-items: center;

        #custom-seekbar {
            cursor: pointer;
            height: 0.4vw;
            margin-bottom: 0px;
            background-color: #b7b7b7;
            position: relative;
            width: 100%;
            border-radius: 0.5vw;

            span {
                background-color: #f26522;
                position: absolute;
                top: 0;
                left: 0;
                height: 0.4vw;
                width: 0%;
                border-radius: 0.5vw;
                z-index: 1;
            }
            div {
                position: absolute;
                content: '';
                background-color: #f26522;
                left: 0%;
                top: -0.49vw;
                height: 1.3vw;
                width: 1.3vw;
                display: block;
                border-radius: 50%;
                border: 0.12vw solid #fff;
            }
        }
    }
}
