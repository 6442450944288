.notifications-container {
    position: absolute;
    top: 8vh;
    right: -1.5vw;
    background-color: #fff;
    z-index: 999999999999999;
    width: 28vw;
    box-shadow: 0px 2vw 3vw 0 rgba(0, 0, 0, 0.16);
    border-radius: 1vw;
    height: 36.5vw;

    .corner {
        position: absolute;
        height: 1vw;
        width: 1vw;
        background-color: #fff;
        top: -0.5vw;
        right: 2.5vw;
        transform: rotate(45deg);
        border-radius: 0.3vw 0 0 0;
    }

    .notification-in {
        height: 33.5vw;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0vw 1.5vw;
    }

    .notification {
        display: flex;
        padding: 1.5vw 0;
        border-bottom: 0.1vw solid rgba(102, 102, 102, 0.4);

        .dot {
            width: 0.8vw;
            height: 0.8vw;
            background-color: #666;
            border-radius: 50%;
            margin-top: 0.3vw;
            align-items: center;
            margin-right: 1vw;
            flex-shrink: 0;
            position: relative;
        }
        .notification-info h3 a {
            display: inline-block;
            background: none;
            padding: 0;
            height: unset;
            margin: 0;
            color: #51bbe7;
            pointer-events: all;
        }

        h3 {
            font-size: 1.1vw;
            font-weight: 700;
            color: #000;
            pointer-events: none;
        }
        .notification-timer {
            font-size: 1vw;
            color: #666;
            margin-top: 0.5vw;
        }
    }

    .notification:last-child {
        margin-bottom: 2vw;
    }

    .btn-view-all {
        color: #51bbe7 !important;
        display: block;
        margin-top: 1vw;
        margin-bottom: 1vw;
        text-align: center;
        font-size: 1.3vw;
        font-weight: 700;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        margin-left: 0 !important;
    }

    .not-seen {
        background-color: #51bbe7 !important;
    }
}
