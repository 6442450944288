.loader-spinner img {
    height: 15vw;
    position: absolute;
    left: 40vw;
    top: 12vw;
    z-index: 10;
}
.loader-spinner-text img {
    height: 13vw;
    position: absolute;
    top: 24vw;
    left: 23vw;
    z-index: 10;
}
