.avatar-main-img {
    height: 5.5vw;
    width: 100%;
    background-color: #fff;
    position: relative;
    border-radius: 50%;
}
.av-img-1 {
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}
.avatar-main-img img{
    width: 5.5vw;
    height: 5.5vw;
    margin: 0 auto;
    cursor: pointer;
    display: block;
}