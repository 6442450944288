.preposition-settings {

    .modal-dialog {
        max-height: 70vh;
        max-width: 35vw;
    }
    .switch {
        display: inline-block;
        position: relative;
        width: 2.7vw;
        height: 1.5vw;
        border-radius: 4vw;
        background: #c2c2c2;
        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        vertical-align: middle;
        cursor: pointer;
        margin-bottom: 0;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0.1vw;
            width: 1.4vw;
            height: 1.4vw;
            background: #fafafa;
            border-radius: 50%;
            transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
        }

        &:active {
            &::before {
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
            }
        }

        &.checked {
            background: #4fd262;

            &::before {
                left: 1.2vw;
                background: #ffffff;
            }
        }
    }

    .circular-div {
        border: 1px solid #c0c1c0;
        border-radius: 3vw;
        padding: .3vw 1.1vw;
        margin: 1vw 0;
    }
    .modal-w {
		padding: 0 1.5vw;
		display: block;
		text-align: center;
    }

    .user-1 {
		text-align: center;
		font-size: 1.3vw;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		.avatar-main-img{
			width: 4.5vw;
			height: 4.5vw;
    		border: 1px solid rgba(0,0,0,.2);
			img.av-img-1{
				bottom: 0px;
			}
		}
		img {
			width: 4.5vw;
			height: auto;
		}
		span {
			display: block;
			margin-left: 0.5vw;
		}
	}
}