.settings-sec {
  position: absolute;
  width: 24vw;
  height: auto;
  /* display: none; */
  background-color: #fff;
  z-index: 999999;
  right: 3vw;
  top: 8vh;
  padding: 1vw 1.5vw;
  border-radius: 1vw;
  -webkit-box-shadow: 0px 2vw 3vw 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0px 2vw 3vw 0 rgba(0, 0, 0, 0.16);
}

.settings-sec .fr {
  position: absolute;
  height: 1vw;
  width: 1vw;
  background-color: #fff;
  top: -.5vw;
  right: 1.7vw;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.settings-sec .se-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1vw 0;
}

.settings-sec .se-div p {
  margin-bottom: 0vw;
  font-size: 1vw;
  font-weight: 600;
}

.settings-sec .se-div .switch {
  display: inline-block;
  position: relative;
  width: 4vw;
  height: 1.8vw;
  border-radius: 4vw;
  background: #00a651;
  -webkit-transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
}

.settings-sec .se-div .switch::before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: .20vw;
  width: 1.4vw;
  height: 1.4vw;
  background: #fafafa;
  border-radius: 50%;
  -webkit-transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.settings-sec .se-div .switch:active::before {
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

.settings-sec .se-div input:checked+.switch {
  background: #ed1c24;
}

.settings-sec .se-div input:checked+.switch::before {
  left: 2.4vw;
  background: #fff;
}

.settings-sec .se-div input:checked+.switch:active::before {
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

.settings-sec .pagination {
  margin-bottom: 0px;
}

.settings-sec .pagination>li {
  padding-left: 5px
}

.settings-sec .pagination>li>a {
  position: relative;
  margin-left: -1px;
  color: #189cd3;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #189cd3;
  height: 2vw;
  width: 2vw;
  display: flex;
  justify-content: center;
}

.settings-sec .pagination>.active>a {
  color: #fff;
  cursor: default;
  background-color: #189cd3;
  border-color: #189cd3;
}