header {
    width: 100vw;
    height: 8vh;
    background-color: #ffffff;
    .data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4vw;
        height: 8vh;
    }
    @media (max-width: 768px) {
        .data {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 4vw;
            height: auto;
        }
    }
    .logo a {
        display: flex;
        height: 8vh;
        align-items: center;
        color: #fff;
        font-weight: 900;
        font-size: 1.2vw;
        text-decoration: none;
    }
    .logo img {
        width: auto;
        height: 5vh;
        border-radius: 0.4vw;
        margin-right: 1vw;
    }
    @media (max-width: 1440px) {
        .logo img {
            width: auto;
            height: 35px;
            border-radius: 0.4vw;
            margin-right: 1vw;
        }
    }
    .links {
        display: flex;
        align-items: center;
    }
    .links a {
        background-color: #ededed;
        display: inline-block;
        height: 5.5vh;
        margin-left: 0.8vw;
        text-decoration: none;
        padding: 1vh 1vw;
        border-radius: 0.5vw;
        display: flex;
        color: #4c4c4c;
        font-size: 1vw;
        font-weight: 700;
        align-items: center;
        transition: 0.5s;
        img {
            width: 2vw;
            height: 4vh;
            border-radius: 50%;
            margin-right: 0.5vw;
        }
        @media (max-width: 1440px) {
            img {
                width: 25px;
                height: 25px;
            }
        }
        @media (max-width: 1024px) {
            img {
                width: 3vw;
                height: 25px;
                border-radius: 0.4vw;
                margin-right: 1vw;
            }
        }
        @media (max-width: 1024px) {
            img {
                width: 20px;
                height: 20px;
                border-radius: 0.4vw;
                margin-right: 1vw;
            }
        }
        @media (max-width: 768px) {
            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 0.5vw;
            }
        }
        &:hover {
            background-color: #d5d5d5;
        }
    }
    @media (max-width: 1440px) {
        .links a {
            height: 45px;
        }
    }
    @media (max-width: 1366px) {
        .links a {
            height: 40px;
        }
    }
    @media (max-width: 1280px) {
        .links a {
            height: 45px;
        }
    }
    @media (max-width: 1280px) {
        .links a {
            height: 30px;
        }
    }
    .notify-main {
        position: relative;
        .notify-sub {
            background-color: #ed1c24;
            height: 1.3vw;
            width: 1.3vw;
            display: inline-block;
            position: absolute;
            top: -0.1vw;
            text-align: center;
            font-size: 0.7vw;
            right: -0.5vw;
            line-height: 1.3vw;
            border-radius: 50%;
            color: #fff;
        }
    }
}
.student-header {
    box-shadow: 0 2vw 3vw 0 rgba(0, 0, 0, 0.16);
    height: 8.5vh;

    .data {
        height: 8.5vh;

        .links {
            a {
                position: relative;
                height: 5.5vh;

                img {
                    width: 4.3vh;
                    height: 4.3vh;
                }

                span.badge {
                    position: absolute;
                    top: -0.5vw;
                    right: -0.5vw;
                    background: #ed1c24;
                    height: 1.2vw;
                    width: 1.2vw;
                    border-radius: 1.2vw;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.8vw;
                    font-weight: normal;
                }
                .fas {
                    font-size: 1.4vw;
                }
            }

            .nav-link {
                background: transparent;
                color: #4d4d4d;
                font-size: 0.8vw;
                font-weight: 400;
                display: block;
                height: auto;
                border-right: 0.15vw solid #ededed;
                border-radius: 0;
                line-height: 1.3;
                padding: 0 1.3vw;
                margin: 0;

                span {
                    display: block;
                    font-size: 1vw;
                    line-height: normal;
                    font-weight: 500;
                }

                &:hover {
                    background: transparent;
                }
            }
            .student-avatar {
                .avatar-main-img {
                    width: 2vw;
                    height: 4vh;
                    margin-right: 0.5vw;
                    background-color: transparent;
                    margin-bottom: 3px;
                }
            }
        }
    }
}
