.note-dropdown {
    position: absolute;
    width: 35vw;
    background-color: #fff;
    z-index: 99999;
    right: 6vw;
    top: 8vh;
    padding: 1vw 1.5vw;
    border-radius: 1vw;
    -webkit-box-shadow: 0px 2vw 3vw 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0px 2vw 3vw 0 rgba(0, 0, 0, 0.16);
    height: 0;
    display: none;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}

.note-dropdown .fr {
    position: absolute;
    height: 1vw;
    width: 1vw;
    background-color: #fff;
    top: -0.5vw;
    right: 1.7vw;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.note-dropdown .nav-link {
    padding: 0.5vw 0;
    margin-right: 1.8vw;
    color: #666;
    font-size: 1vw;
    border-radius: 0;
    border: none;
    font-weight: 600;
}

.note-dropdown .nav-link.active {
    background-color: none !important;
    border-color: transparent;
    border-bottom: 0.25vw solid #189cd3;
    color: #189cd3;
    font-weight: 600;
}

.note-dropdown .note-dr-p {
    font-size: 1.4vw;
    font-weight: 600;
    margin-bottom: 0;
}

.note-dropdown .dr-para {
    background-color: #f0f3f1;
    margin: 1vw 0;
    padding: 1vw;
    border-radius: 1vw;
    font-size: 1vw;
}

.note-dropdown .dr-sve-btn {
    background-color: #29abe1;
    color: #fff !important;
    height: 2vw;
    width: 6vw;
    border-radius: 0.7vw;
    font-size: 1vw;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
}

.note-dropdown.active {
    height: auto;
    display: block;
}

.notes {
    background-color: rgb(240, 243, 241);
    height: 100%;
    width: 100%;
    border: none;
}

a {
    cursor: pointer;
}
