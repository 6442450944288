.carousel-container {
    height: 100%;
    .carousel {
        height: 100%;
        .carousel-item {
            height: 100%;
        }
        .carousel-indicators {
            display: none;
        }
        .carousel-inner {
            height: 100%;
            border-radius: 1vh !important;
            .carousel-item {
                height: 100%;

                .img-container {
                    height: 100%;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                    .carousel-caption {
                        height: 10px;
                        color: inherit;
                        display: none;
                    }
                }
            }
        }

        .carousel-control-next,
        .carousel-control-prev {
            background-image: none !important;
            z-index: 99;

            .rightControl,
            .leftControl {
                i {
                    margin-top: -12px;
                    color: black !important;
                }
            }
        }
    }
}
