.common-welcome-pop {
    padding: 0 !important;

    &.modal.show {
        .modal-dialog {
            width: 100%;
            max-width: 980px;
            top: auto;
            max-height: 100%;
            margin: auto !important;
            padding: 1vw;
        }
    }
    &.modal-backdrop.show {
        opacity: 0.7;
    }
    .modal-content {
        border-radius: 3vw;
        border: none;
        padding: 1.5vw 3vw;
    }
    .modal-content.rhymingIdentification-content {
        background-color: #990505;
    }

    .modal-body {
        padding: 4vw 1.5vw 3vw 1.5vw;
        border-radius: 3vw;

        .welcome-text {
            margin-bottom: 3vw;
            img {
                width: 25vw;
            }
        }
        .logo-contaoner {
            img {
                width: 100%;
            }
        }
        .go-btn {
            display: inline-block;
            padding: 0.7vw 0;
            color: #000;
            background: #fbb300;
            font-size: 3vw;
            text-transform: uppercase;
            font-weight: 800;
            min-width: 18vw;
            border-radius: 1.5vw;
            -webkit-box-shadow: 0 0.6vw 0 #a47708;
            box-shadow: 0 0.6vw 0 #a47708;
            margin-top: 2vw;
            &:hover {
                background: #a47708;
                color: #fff;
            }
        }
    }
    .modal-content.rhymingIdentification-content .modal-body {
        background: #ff0000;
    }
    .modal-content.rhymingMatching-content {
        background: #041829;
        box-shadow: 0 0px 1.5vw #12e6fc inset;
        padding: 0px;
    }
    .modal-content.rhymingMatching-content .modal-body {
        .welcome-text {
            margin-bottom: 2vw;
            h2 {
                color: #3eeafc;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 4vw;
                margin: 0;
            }
        }
    }
    .modal-content.decoding-content {
        background: #75a9c2;
        border: 0.65vw solid #133745;
        padding: 0px;
    }
    .modal-content.decoding-content .modal-body {
        .welcome-text {
            margin-bottom: 2vw;
        }
        .go-btn {
            background: #a0c013;
            box-shadow: 0 0.6vw 0 #6c8c0a;
            color: #fff;
            padding: 0.2vw 0;
            &:hover {
                background: #6c8c0a;
            }
        }
    }
    .modal-content.adjective-remix-welcome-content .modal-body {
        .go-btn {
            padding: 0.2vw 0;
        }
    }

    &#adjectiveModal {
        .logo-contaoner {
            height: 37vw;
            position: relative;
            img.modal-img {
                height: 34vw;
                object-fit: contain;
            }
        }
        button.go-btn {
            margin-top: 0 !important;
            bottom: 1vw;
            font-size: 1.7vw;
            min-width: 11vw;
        }
    }
}
