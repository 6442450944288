.common-modal {
    &.modal {
        z-index: 9999999;
        .md-c1 {
            margin-left: 2vw;
        }
        &.show {
            .modal-dialog-2 {
                max-width: 47vw;
                .modal-body {
                    padding: 0.5vw 0;
                    overflow: hidden;
                }
                .divider-1 {
                    height: 0.1vw;
                    background-color: #f0f3f1;
                    margin: 0.5vw 0vw;
                }
            }
        }

        .modal-w {
            padding: 0 1.5vw;
            display: block;
            text-align: center;
            .png-box {
                width: 14vw;
                height: 14vw;
                display: inline-block;
                margin: 0.5vw;
                cursor: pointer;
                position: relative;
                img {
                    width: 100%;
                }
                .btn-icon-m2 {
                    position: absolute;
                    top: 0.8vw;
                    right: 0.8vw;
                    height: 1.5vw;
                    width: 1.5vw;
                    border: 0.1vw solid #fff;
                    border-radius: 50%;
                    i {
                        font-size: 1.5vw;
                        color: #fff;
                        top: -0.04vw;
                        position: absolute;
                        right: -0.1vw;
                        display: none;
                        &.active {
                            display: block;
                        }
                    }
                }
            }
        }
        .modal-header {
            background: #29abe1;
            color: #fff;
            padding: 1vw 1vw;

            .close {
                float: right;
                font-size: 1.5vw;
                font-weight: 700;
                line-height: 0.7;
                color: #fff;
                text-shadow: 0;
                opacity: 1;
            }
        }
        .user-1 {
            text-align: center;
            font-size: 1.3vw;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            .avatar-main-img {
                width: 3.5vw;
                height: 3.5vw;
                background-color: #fff;
                border: 2px solid #dfe2e6;
                position: relative;
                border-radius: 50%;
                .av-img-1 {
                    bottom: 0;
                }
            }
            img {
                width: 3vw;
                height: 3vw;
                margin-right: 0.5vw;
            }
            span {
                display: block;
                margin-left: 0.5vw;
                font-size: 1.5vw !important;
                font-weight: 700;
            }
        }
        .modal-content {
            border: none;
            border-radius: 1vw;
            overflow: hidden;

            .modal-header {
                background-color: #00aeef;
                border: none;
                padding: 1vw;
            }
        }
        .settings-box,
        .language-box,
        .item-box,
        .receptive-box {
            border: 1px solid #c0c1c0;
            border-radius: 1.3vw;
            cursor: pointer;
            overflow: hidden;
            margin: 0.8vw 0;
            line-height: 2.5;
        }
        .language-box,
        .item-box,
        .receptive-box {
            padding: 12px 16px;
        }
        .language-box p,
        .item-box p {
            color: #000;
            font-weight: 700;
            font-family: 'Lato';
            font-size: 1vw;
        }
        .item-box {
            .item-numbers {
                span {
                    color: #29abe1;
                    font-weight: 700;
                    font-family: 'Lato';
                    font-size: 1.5vw;
                    border: 2px solid #29abe1;
                    border-radius: 50px;
                    padding: 0.5vw 0.95vw;
                    margin-right: 0.2vw;
                }
            }
        }
        .item-box .item-numbers span.active,
        .language-box .language-btns a.active {
            background-color: #29abe1;
            color: #fff;
        }
        .item-box {
            .row {
                .col-6 {
                    border-right: 2px solid #c0c1c0;
                }
            }
        }
        .language-box {
            .language-btns {
                a {
                    color: #29abe1;
                    font-family: 'Lato';
                    font-size: 1vw;
                    border: 1px solid #29abe1;
                    border-radius: 1vw;
                    padding: 0.5vw 0.95vw;
                    margin-right: 0.2vw;
                }
            }
        }
        .adjective-modal {
            .circular-div {
                border: 1px solid #c0c1c0;
                border-radius: 3vw;
                padding: 0.3vw 1.1vw;
                margin: 1vw 0;
            }
        }

        .settings-box,
        .adjective-modal,
        .verbQuest-modal,
        .level-three-items {
            .row {
                .col-6 {
                    border-right: 2px solid #c0c1c0;
                }
            }
            .custom-space {
                margin: 0 0 1vw 0;
                align-items: center;
            }
            .category-h {
                background-color: #f0f3f1;
                color: #000;
                font-weight: 700;
                font-family: 'Lato';
                text-align: left;
                padding: 6px 16px;
            }
            .category-name {
                color: #000;
                font-weight: 700;
                font-family: 'Lato';
                font-size: 1vw;
                line-height: 2;
            }

            .switch {
                display: inline-block;
                position: relative;
                width: 4vw;
                height: 1.8vw;
                border-radius: 4vw;
                background: #c0c1c0;
                transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                vertical-align: middle;
                cursor: pointer;
                margin-bottom: 0;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0.1vw;
                    width: 1.4vw;
                    height: 1.4vw;
                    background: #fafafa;
                    border-radius: 50%;
                    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                        background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                        box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                }
                &:active {
                    &::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28),
                            0 0 0 20px rgba(128, 128, 128, 0.1);
                    }
                }

                &.checked {
                    background: #4fd262;
                    &::before {
                        left: 1.2vw;
                        background: #ffffff;
                    }
                }
            }
            input {
                &:checked {
                    & + .switch {
                        background: #4fd262;
                        &::before {
                            left: 2.4vw;
                            background: #fff;
                        }
                        &:active {
                            &::before {
                                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28),
                                    0 0 0 20px rgba(0, 150, 136, 0.2);
                            }
                        }
                    }
                }
            }
        }
        .verbQuest-modal,
        .level-three-items {
            .switch {
                background: #c0c1c0;
                &.checked {
                    background: #4fd262;
                    &::before {
                        left: 2.4vw;
                        background: #ffffff;
                    }
                }
            }
        }

        .receptive-box p,
        .w20 p {
            margin-bottom: 0;
            color: #000;
            font-weight: 700;
            font-family: 'Lato';
            font-size: 1vw;
        }
        .adjective-modal .w20 [type='radio']:checked + label,
        .w20 [type='radio']:checked + label,
        .w20 [type='radio']:not(:checked) + label {
            position: relative;
            padding-left: 2vw;
            cursor: pointer;
            display: inline-block;
            margin-bottom: 0;
            line-height: 1.8vw;
        }
        .adjective-modal .w20 [type='radio']:checked + label:before,
        .w20 [type='radio']:checked + label:before,
        .w20 [type='radio']:not(:checked) + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 1.6vw;
            height: 1.6vw;
            border: 0.2vw solid #8c8c8c;
            border-radius: 100%;
            background: #fff;
        }
        .adjective-modal .w20 [type='radio']:checked + label:after,
        .w20 [type='radio']:checked + label:after,
        .w20 [type='radio']:not(:checked) + label:after {
            content: '';
            width: 1.3vw;
            height: 1.3vw;
            background-color: #00aeef;
            border: 0.3vw solid #fff;
            position: absolute;
            top: 0.15vw;
            left: 0.15vw;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
        .w20 {
            [type='radio']:not(:checked) {
                & + label {
                    &:after {
                        opacity: 0;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }
                }
            }
            [type='radio']:checked {
                & + label {
                    &:after {
                        opacity: 1;
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }
                }
            }
        }
        .w20 [type='radio']:checked,
        .w20 [type='radio']:not(:checked) {
            position: absolute;
            left: -9999px;
        }
        .modal-body {
            padding: 0.4vw 2vw 1vw 2vw !important;

            .divider-1 {
                background-color: transparent;
                height: 100%;
                border-top: 0.15vw solid #d9d9d9;
            }
            h3 {
                margin: 1vw 0 0.5vw 0;
                font-weight: bold;
                color: #000;
                font-size: 1.3vw;
            }

            .selected-level-items {
                border: 0.15vw solid #c0c1c0;
                border-radius: 1vw;
                overflow: hidden;
                padding-bottom: 0.5vw;
                margin-bottom: 1.5vw;

                .selected-level-inner {
                    padding: 0.1vw 1vw 0 1vw;
                    height: 20.6vw;
                    overflow: auto;
                }
            }

            .form-group {
                display: flex;
                margin-bottom: 0;
                align-items: center;
                justify-content: space-between;
                border-bottom: 0.1vw solid #c0c1c0;
                padding-bottom: 0.5vw;
                padding-top: 0.5vw;

                &:last-child {
                    border: none;
                    padding-bottom: 0;
                }

                input {
                    padding: 0;
                    height: initial;
                    width: initial;
                    margin-bottom: 0;
                    display: none;
                    cursor: pointer;
                }

                label {
                    position: relative;
                    cursor: pointer;
                    margin: 0;
                    display: block;
                    font-size: 1.3vw;
                    color: #000;

                    &:before {
                        content: '';
                        -webkit-appearance: none;
                        background-color: transparent;
                        border: 0.2vw solid #c0c1c0;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        height: 1.5vw;
                        width: 1.5vw;
                        margin-right: 1vw;
                        border-radius: 0.25vw;
                    }
                }

                input:checked + label:after {
                    border: 0.2vw solid #00aeef;
                    cursor: pointer;
                    height: 1.6vw;
                    width: 1.5vw;
                    border-radius: 0.25vw;
                    content: '\f00c';
                    font-family: 'Font Awesome 5 Free';
                    font-weight: bold;
                    color: #fff;
                    position: absolute;
                    left: 0;
                    top: 0.3vw;
                    background: #00aeef;
                    z-index: 99;
                    font-size: 0.9vw;
                    line-height: 1.3vw;
                    padding-left: 0.15vw;
                }

                strong {
                    font-weight: 900;
                }
            }

            .value {
                color: #a1a1a1;
                font-size: 1.3vw;
                font-weight: bold;
            }
            .level-three-items {
                border: 0.15vw solid #c0c1c0;
                border-radius: 1vw;
                overflow: hidden;
                padding-bottom: 0.5vw;
                margin-bottom: 1vw;

                .level-three-items-header {
                    display: flex;
                    background: #f0f3f1;
                    padding: 0.7vw 1.5vw;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 0.5vw;

                    h4 {
                        margin: 0;
                        font-weight: 900;
                        font-size: 1.8vw;
                        color: #000;
                    }
                }

                .three-level-control {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h4 {
                        margin: 0;
                        font-size: 1.3vw;
                        font-weight: bold;
                        color: #000;
                    }
                }

                .row {
                    margin: 0;

                    .col-sm-4.col-4 {
                        padding: 0.7vw 1.5vw;
                        border-right: 0.15vw solid #c2c2c2;

                        &:nth-child(3) {
                            border-right: none;
                        }

                        &:nth-child(6) {
                            border-right: none;
                        }
                    }
                }

                .slider {
                    background-color: #c2c2c2;
                    bottom: 0;
                    cursor: pointer;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transition: 0.4s;

                    &:before {
                        background-color: #fff;
                        bottom: 0.1vw;
                        content: '';
                        height: 1.4vw;
                        left: 0.1vw;
                        position: absolute;
                        -webkit-transition: 0.4s;
                        transition: 0.4s;
                        width: 1.4vw;
                        box-shadow: 0 0.2vw 0.5vw rgba(0, 0, 0, 0.5);
                    }
                }

                .slider.checked {
                    background-color: #4fd262;

                    &:before {
                        transform: translateX(1.4vw);
                    }
                }

                .slider.round {
                    border-radius: 4vw;

                    &:before {
                        border-radius: 4vw;
                    }
                }
            }

            .levels {
                height: 11vw;
                overflow: auto;
                overflow-x: hidden;
            }
        }
        .user-outer {
            padding-top: 0;
            padding-bottom: 0.7vw;

            .avatar-main-img {
                width: 5.5vw;
                height: 5.5vw;
                border: 1px solid rgba(0, 0, 0, 0.2);

                img {
                    bottom: 0px;
                    width: 4.5vw;
                    height: auto;
                }
            }

            span.child-name {
                display: block;
                margin-left: 0.5vw;
            }

            h3 {
                color: #000;
                font-weight: bold;
                font-size: 1.2vw;
                margin: 1vw 0 0.5vw 0;
            }

            .thumb {
                border: 0.1vw solid #cccccc;
                height: 4vw;
                width: 4vw;
                border-radius: 5vw;
                overflow: hidden;
                margin-right: 0.5vw;
                text-align: center;

                img {
                    height: 100%;
                    width: 100%;
                    margin: auto;
                }
            }
        }
        .preposition-modal {
            max-height: 70vh;
            max-width: 60vw;
            margin-left: auto;
            margin-right: auto;
        }
        .levels {
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-track {
                background: #fff;
                border-radius: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background: #8c8c8c;
                border-radius: 6px;
            }
        }
        .selected-level-inner {
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-track {
                background: #fff;
                border-radius: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background: #8c8c8c;
                border-radius: 6px;
            }
        }
    }
    &#game-settings {
        .modal-d.modal-w {
            max-height: 37vw;
            overflow: auto;
        }
    }
}
