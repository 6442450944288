@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

* {
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Lato", sans-serif;
  caret-color: transparent;
}

input,
textarea {
  caret-color: #000;
}

.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 400ms;
}
.modal {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.finish-animation {
  position: absolute;
  top: -10vh;
  z-index: 999;
}

.bg-correct {
  background: #14a800;
}
.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}
.border-correct {
  border: 3px solid #14a800 !important;
}

/* Just add .fade-in class to element */

.fade-in {
  animation: fadeIn 2.5s;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Just add .fade-out class to element */

.fade-out {
  animation: fadeOut 2.5s;
  opacity: 0;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
