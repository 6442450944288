.following-direction-modal {
	&.modal {
		z-index: 9999999;
		.md-c1 {
			margin-left: 2vw;
		}
		&.show {
			.modal-dialog-2 {
				max-width: 47vw;
				.modal-body {
					padding: 0.5vw 0;
					overflow: hidden;
				}
				.divider-1 {
					height: 0.1vw;
					background-color: #f0f3f1;
					margin: 0.5vw 0vw;
				}
			}
		}

		.modal-w {
			padding: 0 1.5vw;
			display: block;
			text-align: center;
			.png-box {
				width: 14vw;
				height: 14vw;
				display: inline-block;
				margin: 0.5vw;
				cursor: pointer;
				position: relative;
				img {
					width: 100%;
				}
				.btn-icon-m2 {
					position: absolute;
					top: 0.8vw;
					right: 0.8vw;
					height: 1.5vw;
					width: 1.5vw;
					border: 0.1vw solid #fff;
					border-radius: 50%;
					i {
						font-size: 1.5vw;
						color: #fff;
						top: -0.04vw;
						position: absolute;
						right: -0.1vw;
						display: none;
						&.active {
							display: block;
						}
					}
				}
			}
		}
		.modal-header {
			background: #29abe1;
		}
		.user-1 {
			text-align: center;
			font-size: 1.3vw;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			.avatar-main-img {
				width: 3.5vw;
				height: 3.5vw;
				background-color: #fff;
				border: 2px solid #dfe2e6;
				position: relative;
				border-radius: 50%;
				.av-img-1 {
					bottom: 0;
				}
			}
			img {
				width: 3vw;
				height: 3vw;
				margin-right: 0.5vw;
			}
			span {
				display: block;
				margin-left: 0.5vw;
				font-size: 1.5vw !important;
				font-weight: 700;
			}
		}
	
		.item-box{
			border: 1px solid #c0c1c0;
			border-radius: 1.3vw;
			cursor: pointer;
			overflow: hidden;
			margin: 0.8vw 0;
		}
	
		.item-box{
			padding: 12px 16px;
		}

		.item-box p {
			color: #000;
			font-weight: 700;
			font-family: "Lato";
			font-size: 1vw;
		}
		.item-box {
			.item-numbers {
				span {
					color: #29abe1;
					font-weight: 700;
					font-family: "Lato";
					font-size: 1.5vw;
					border: 2px solid #29abe1;
					border-radius: 50px;
					padding: 0.5vw 0.95vw;
					margin-right: 0.2vw;
				}
			}
		}
		.item-box .item-numbers span.active,
		.language-box .language-btns a.active {
			background-color: #29abe1;
			color: #fff;
		}

	
		.item-box {
			.row {
				.col-6 {
					border-right: 2px solid #c0c1c0;
				}
			}
			.switch {
				display: inline-block;
				position: relative;
				width: 4vw;
				height: 1.8vw;
				border-radius: 4vw;
				background: #4fd262;
				transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
				vertical-align: middle;
				cursor: pointer;
				margin-bottom: 0;
				&::before {
					content: "";
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0.2vw;
					width: 1.4vw;
					height: 1.4vw;
					background: #fafafa;
					border-radius: 50%;
					transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
						box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
				}
				&:active {
					&::before {
						box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
					}
				}
			}
			input {
				&:checked {
					& + .switch {
						background: #c2c2c2;
						&::before {
							left: 2.4vw;
							background: #fff;
						}
						&:active {
							&::before {
								box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
							}
						}
					}
				}
			}
		}
	}
}
