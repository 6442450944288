.well-done-popup{
    display: block;
    position: relative;
    .wrapper{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .user-1 span{
            font-size: 1.5vw;
            font-weight: bold;
            left: 19.5vw;
            top: 10vw;
            position: absolute;
        }
        .avatar-main-img {
            height: 7vw;
            width: 7vw;
            background-color: #fff;
            position: absolute;
            border-radius: 50%;
            left: 19vw;
            top: 3vw;
        }
        .av-img-1 {
            position: absolute;
            bottom: 17px;
            left: 50%;
            transform: translateX(-50%);
        }
        .duration{
            position: absolute;
            top: 19.5vw;
            left: 20vw;
            font-size: 1.2vw;
        }
        .well-done-image{
            width: 43vw;
        }
        .close-icon{
            cursor: pointer;
            width: 2vw;
            position: absolute;
            top: 4vw;
            left: 39vw;
        }
        .progress-outer{
            width: 27vw;
            position: absolute;
            top: 22vw;
            left: 8.5vw;
        }
        .progress-label{
            display: flex;
            justify-content: space-between;
        }
        .tell-my-teacher{
            font-size: 1.2vw;
            font-family: "Lato";
            color: rgb(255, 255, 255);
            text-transform: uppercase;
            line-height: 1.2;
            text-align: center;
            background-color: rgb(234, 32, 38);
            position: absolute;
            left: 14.5vw;
            top: 26vw;
            width: 16vw;
            height: 3vw;
            border-radius: 1vw;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
.timer-progress-bar{
    width: 100%;
    height: 1vw;
    border: 1px solid #189cd3;
    border-radius: 1vw;
    overflow: hidden;
    .inner{
        background:#189cd3;
        height: 100%;
    }
}
