.customer-support-modal {
	&.modal {
		z-index: 9999999;
		&.show {
			.modal-dialog-2 {
				max-width: 47vw;
				.modal-body {
					padding: 0.5vw 0;
					overflow: hidden;
				}
				.divider-1 {
					height: 0.1vw;
					background-color: #f0f3f1;
					margin: 0.5vw 0vw;
				}
			}
		}

		.modal-w {
			padding: 0 1.5vw;
			display: block;
			text-align: center;
			.png-box {
				width: 14vw;
				height: 14vw;
				display: inline-block;
				margin: 0.5vw;
				cursor: pointer;
				position: relative;
				img {
					width: 100%;
				}
				.btn-icon-m2 {
					position: absolute;
					top: 0.8vw;
					right: 0.8vw;
					height: 1.5vw;
					width: 1.5vw;
					border: 0.1vw solid #fff;
					border-radius: 50%;
					i {
						font-size: 1.5vw;
						color: #fff;
						top: -0.04vw;
						position: absolute;
						right: -0.1vw;
						display: none;
						&.active {
							display: block;
						}
					}
				}
			}
		}
		.modal-header {
			background: #495057;
		}

		textarea {
			width: 100%;
			border: 2px solid #eee;
			padding: 10px;
			height: 15vw;
			margin-top: 15px;
			outline: none;
		}
		textarea:focus,
		textarea:focus-visible,
		textarea:active {
			border: 2px solid #ddd;
		}
		.btn {
			background: #29abe1;
			width: 100%;
			color: #fff;
			margin-top: 20px;
			margin-bottom: 40px;
		}
	}
}
