.BrowserNotSupportedModal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    z-index: 9999;
    overflow: hidden;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.hide {
        animation: hide_modal 0.6s forwards;
    }
    .center {
        transform: scale(0);
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        width: 80%;
        max-width: 600px;
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5);
        margin: 10% auto;
        left: 0;
        right: 0;
        animation: show_modal 0.6s forwards;

        &.hide {
            animation: hide_modal 0.6s forwards;
        }

        @keyframes show_modal {
            0% {
                transform: scale(0);
            }
            60% {
                transform: scale(1.15);
            }
            80% {
                transform: scale(0.95);
            }
            100% {
                transform: scale(1);
            }
        }

        @keyframes hide_modal {
            0% {
                transform: scale(1);
            }
            20% {
                transform: scale(1.3);
            }
            100% {
                transform: scale(0);
            }
        }

        p {
            text-align: center;
            line-height: 1.5rem;
            letter-spacing: 0.01rem;
        }

        big {
            display: inline-block;
            margin-bottom: 0.8rem;
        }

        button {
            border: none;
            width: 100%;
            bottom: -1px;
            padding: 0.8rem;
            cursor: pointer;
            color: white;
            background-color: #f65656;
            display: block;
            vertical-align: bottom;
            transition: background 0.3s ease-in-out;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        button:hover {
            background-color: #ec3434;
        }
    }

    .mb-1 {
        margin-bottom: 0.5rem;
    }

    .alert-icon {
        color: #f65656;
        font-size: 35px;
    }
}
